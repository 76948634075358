var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_setup.isLoadingStore),expression:"isLoadingStore"}]},[_c(_setup.virtualList,{directives:[{name:"show",rawName:"v-show",value:(_setup.storeFinalData.length),expression:"storeFinalData.length"}],attrs:{"table-data":_setup.storeFinalData,"table-head-prop":_setup.bujianStoreList,"show-data-length":_setup.props.showDataLength,"single-item-height":40,"showHeadBorder":false,"is-show-back-top":false,"borderColor":"#dcdcdc","preffixID":"bujian_store","align":"left"},on:{"onScrollBottom":_setup.handleScrollBottom,"changeSelect":_setup.handleChangeSelect},scopedSlots:_vm._u([{key:"headSelect",fn:function(){return [_c('a-checkbox',{attrs:{"id":"workshop_bujian_store_head_checkbox"},on:{"change":_setup.handleStoreSelectAll},model:{value:(_setup.isSelectAll),callback:function ($$v) {_setup.isSelectAll=$$v},expression:"isSelectAll"}})]},proxy:true},{key:"rowHead",fn:function(scope){return [_c('div',{staticClass:"w100 head-row-line flex"},[_c('a-checkbox',{attrs:{"id":`workshop_bujian_store_list_${scope.plank.address}_checkbox`},on:{"change":function($event){return _setup.handleStoreSelect(scope.plank, 'singleMatCode')}},model:{value:(scope.plank.isSelect),callback:function ($$v) {_vm.$set(scope.plank, "isSelect", $$v)},expression:"scope.plank.isSelect"}}),_c('div',{staticClass:"order-customer"},[_c('span',{class:[
              'fold-icon expand',
              'iconfont',
              scope.plank.isUnfold ? 'icon-arrow' : 'icon-zhankai',
              'mr5',
            ],on:{"click":() => {
                scope.plank.isUnfold = !scope.plank.isUnfold
              }}}),_c('span',{staticClass:"order"},[_vm._v(" 订单："+_vm._s(scope.plank.address))]),_c('span',{staticClass:"customer"},[_vm._v(" 客户："+_vm._s(scope.plank.customer_name)+" ")])])],1)]}},{key:"index",fn:function(scope){return [_vm._v(_vm._s(scope.index + 1))]}},{key:"operate",fn:function(scope){return [_c('div',{staticClass:"bujian-operate w100"},[_vm._t("operate",function(){return [_c('span',{attrs:{"id":`workshop_bujian_store_list_${scope.index}_paiban_btn`},on:{"click":function($event){return _setup.handleToPaiban([scope.part])}}},[_vm._v("开始排版")]),_c('span',{attrs:{"id":`workshop_bujian_store_list_${scope.index}_delete_btn`},on:{"click":function($event){return _setup.handleStoreDelete(scope.part)}}},[_vm._v("删除")])]},{"part":scope.part,"index":scope.index})],2)]}}],null,true)}),_c('a-empty',{directives:[{name:"show",rawName:"v-show",value:(!_setup.storeFinalData.length),expression:"!storeFinalData.length"}]}),(_setup.isVisible)?_c(_setup.GBaseModal,{attrs:{"visible":_setup.isVisible},on:{"ok":function($event){return _setup.handleConfrimDelete(_setup.isBatchOpe)},"cancel":function($event){_setup.isVisible = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }